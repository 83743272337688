@mixin remove-button-styles {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
  text-align: inherit;
}

@mixin badge {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;

  color: #000;
}

.gm-button:not(.gm-button--plain) {
  @include badge;
  --widget-border: #000;
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 40px;
  justify-content: center;
  border: 1px solid var(--widget-border);
  background-color: white;
  padding: 0 10px;
  color: #000;
  user-select: none;

  &:hover:not(:disabled) {
    background-color: #ddd;
  }
}

.gm-button--plain {
  @include remove-button-styles;
}
