@mixin visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

@mixin badge {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;

  color: #000;
}

.gm-file-picker {
  &__element {
    @include visually-hidden;
  }

  &__label {
    &:not(.gm-file-picker__label--plain) {
      @include badge;
      --widget-border: #000;
      align-items: center;
      border-radius: 4px;
      display: flex;
      height: 40px;
      justify-content: center;
      border: 1px solid var(--widget-border);
      background-color: white;
      padding: 0 10px;
      color: #000;

      :not(:disabled) + &:hover {
        background-color: #ddd;
      }
    }

    &--plain {
      display: inline-flex;
      vertical-align: bottom;
    }
  }
}
