@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Nunito+Sans:wght@400;600;700&family=Roboto:wght@400;700&display=swap);
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gm-button:not(.gm-button--plain){font-family:Montserrat;font-style:normal;font-weight:600;font-size:10px;line-height:12px;text-transform:uppercase;color:#000;--widget-border: #000;align-items:center;border-radius:4px;display:flex;height:40px;justify-content:center;border:1px solid var(--widget-border);background-color:#fff;padding:0 10px;color:#000;-webkit-user-select:none;user-select:none}.gm-button:not(.gm-button--plain):hover:not(:disabled){background-color:#ddd}.gm-button--plain{-webkit-appearance:none;appearance:none;background:none;border:0;border-radius:0;color:inherit;cursor:pointer;font:inherit;outline:inherit;padding:0;text-align:inherit}
.gm-popover__background{-webkit-appearance:none;appearance:none;background:none;border:0;border-radius:0;color:inherit;cursor:pointer;font:inherit;outline:inherit;padding:0;text-align:inherit;content:"";display:block;position:fixed;top:0;left:0;bottom:0;right:0;width:100%;height:100%;background-color:rgba(0,0,0,.4)}.gm-popover__content{position:fixed;opacity:0;transition:.1s opacity;background-color:#fff;z-index:1000;overflow:scroll;border-radius:8px;box-shadow:0 0 3px #000,0 1px 2px #000}.gm-popover__closer{position:absolute;z-index:1;top:6px;right:6px}.gm-popover__closer-path{fill:none;stroke:#000;stroke-width:1px}.gm-popover__children{min-width:200px}
.gm-text-field{font-family:Montserrat;font-style:normal;font-weight:600;font-size:10px;line-height:12px;text-transform:uppercase;color:#000;--widget-border: #000;align-items:center;border-radius:4px;display:flex;width:100%;height:40px;justify-content:center;border:1px solid var(--widget-border);background-color:#fff;padding:0 10px;color:#000;text-transform:none}.gm-text-field::-webkit-input-placeholder{color:#a5a5a5}.gm-text-field::placeholder{color:#a5a5a5}
.gm-vstack{display:flex;flex-flow:column nowrap;margin-top:-16px;margin-left:-16px}.gm-vstack__item{display:block;position:relative;padding-top:16px;padding-left:16px}
.gm-panel{border:2px dashed #79685a;box-sizing:border-box;border-radius:8px;padding:10px}
.media-tile{-webkit-appearance:none;appearance:none;background:none;border:0;border-radius:0;color:inherit;cursor:pointer;font:inherit;outline:inherit;padding:0;text-align:inherit;font-family:Montserrat;font-style:normal;font-weight:600;font-size:10px;line-height:12px;text-transform:uppercase;color:#000;text-align:center;display:inline-flex;width:124px;height:186px;align-items:center;justify-content:center;background-color:#333;color:#fff;vertical-align:bottom;background-position:center;background-size:contain;background-repeat:no-repeat}
.gm-file-picker__element{clip:rect(1px, 1px, 1px, 1px);height:1px;overflow:hidden;position:absolute !important;white-space:nowrap;width:1px}.gm-file-picker__label:not(.gm-file-picker__label--plain){font-family:Montserrat;font-style:normal;font-weight:600;font-size:10px;line-height:12px;text-transform:uppercase;color:#000;--widget-border: #000;align-items:center;border-radius:4px;display:flex;height:40px;justify-content:center;border:1px solid var(--widget-border);background-color:#fff;padding:0 10px;color:#000}:not(:disabled)+.gm-file-picker__label:not(.gm-file-picker__label--plain):hover{background-color:#ddd}.gm-file-picker__label--plain{display:inline-flex;vertical-align:bottom}
.gm-text{font-family:Roboto;font-style:normal;font-weight:normal;font-size:16px;line-height:19px;text-align:center;color:#676767}
.gm-hstack{display:flex;flex-flow:row nowrap}.gm-hstack__items{display:flex;flex-flow:row nowrap;margin-top:-16px;margin-left:-16px}.gm-hstack__item{display:block;position:relative;padding-top:16px;padding-left:16px}
.live-preview{z-index:0}
.gm-select{font-family:Montserrat,sans-serif;font-style:normal;font-weight:600;font-size:10px;line-height:12px;text-transform:uppercase;color:#000;--widget-border: #000;align-items:center;border-radius:4px;display:flex;width:100%;height:40px;justify-content:center;border:1px solid var(--widget-border);background-color:#fff;padding:0 10px;color:#000;text-transform:none}.gm-select::-webkit-input-placeholder{color:#a5a5a5}.gm-select::placeholder{color:#a5a5a5}
.gm-heading{font-family:Nunito Sans;font-style:normal;font-weight:900;font-size:32px;line-height:48px;text-align:center}

#root {
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

