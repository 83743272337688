@mixin remove-button-styles {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
  text-align: inherit;
}

.gm-popover {
  &__background {
    @include remove-button-styles;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__content {
    position: fixed;
    // top: 16px;
    // left: 16px;
    // bottom: 16px;
    // right: 16px;
    opacity: 0;
    transition: 0.1s opacity;
    background-color: white;
    z-index: 1000;
    overflow: scroll;
    border-radius: 8px;
    box-shadow: 0 0 3px black, 0 1px 2px black;
  }

  &__closer {
    position: absolute;
    z-index: 1;
    top: 6px;
    right: 6px;
  }

  &__closer-path {
    fill: none;
    stroke: black;
    stroke-width: 1px;
  }

  &__children {
    min-width: 200px;
  }
}
