@mixin remove-button-styles {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
  text-align: inherit;
}

@mixin badge {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;

  color: #000;
}

.media-tile {
  @include remove-button-styles;
  @include badge;
  text-align: center;
  display: inline-flex;
  width: 124px;
  height: 186px;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  color: white;
  vertical-align: bottom;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
