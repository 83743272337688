.gm-hstack {
  display: flex;
  flex-flow: row nowrap;
}

.gm-hstack__items {
  display: flex;
  flex-flow: row nowrap;
  margin-top: -16px;
  margin-left: -16px;
}

.gm-hstack__item {
  display: block;
  position: relative;
  padding-top: 16px;
  padding-left: 16px;
}
