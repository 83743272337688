.gm-vstack {
  display: flex;
  flex-flow: column nowrap;
  margin-top: -16px;
  margin-left: -16px;
}

.gm-vstack__item {
  display: block;
  position: relative;
  padding-top: 16px;
  padding-left: 16px;
}
