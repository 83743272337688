@mixin badge {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;

  color: #000;
}

.gm-select {
  @include badge;
  --widget-border: #000;
  align-items: center;
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  border: 1px solid var(--widget-border);
  background-color: white;
  padding: 0 10px;
  color: #000;
  text-transform: none;

  &::placeholder {
    color: #a5a5a5;
  }
}
